const isDev = process.env.NODE_ENV === "development"

let BASE_URL = isDev ? 'http://servis.aybitpos.com/aybitpos/' : 'http://servis.aybitpos.com/aybitpos/';

if (isDev) {
    //BASE_URL = 'http://192.168.1.3:3441/vitrinlik_yeni/vapi/';
}

const HEADERS = {
    'pragma': 'no-cache',
    'cache-control': 'no-cache'
}

export {
    HEADERS,
    BASE_URL
}