<template>
  <div id="app">
    <notifications></notifications>
    <router-view/>
  </div>
</template>

<script>

import api from '@/api/api'

export default {
    data() {
        return {
            musteriInterval: null
        }
    },
    mounted() {
        this.aktiflikKontrolEt()

        const ON_DAKIKA_IN_MILISANIYE = 600000;

        this.musteriInterval = setInterval(() => {
            this.aktiflikKontrolEt()
        }, ON_DAKIKA_IN_MILISANIYE)
    },
    methods: {
        async aktiflikKontrolEt() {
            const { ID, MusteriNo } = api.getUser()

            if (MusteriNo) {
                const form = new FormData()

                form.append('ID', ID)
                form.append('MusteriNo', MusteriNo)

                const response = await (await api.kontrol(form)).json();

                const { aktif } = response;

                if (!aktif) {
                    api.User = {}
                    localStorage.removeItem('yonetimPosPanel')
                    this.$router.push('/giris')

                    clearInterval(this.musteriInterval)
                }
            } else {
                clearInterval(this.musteriInterval)
            }
        }
    }
}
</script>