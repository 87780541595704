<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
        <div class="container-fluid">

            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>
            <router-link class="navbar-brand" to="/">
                <img :src="logo" class="navbar-brand-img" alt="...">
            </router-link>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img :src="logo">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>
                <div v-if="guncellemeZamani" class="mt-md-3 ">
                    <p class="guncelleme-zamani mb-0">Güncelleme Zamanı:</p>
                    <p class="guncelleme-zamani">{{ guncellemeZamani }}</p>
                </div>
            </div>
            
            </div>
    </nav>
</template>
<script>
  import NavbarToggleButton from '@/components/NavbarToggleButton'

  import api from '@/api/api'
  import moment from 'moment';

  export default {
    name: 'sidebar',
    components: {
      NavbarToggleButton
    },
    props: {
      logo: {
        type: String,
        default: 'img/brand/aybitpos_logo.png',
        description: 'Sidebar app logo'
      },
      autoClose: {
        type: Boolean,
        default: true,
        description: 'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    data() {
        return {
            guncellemeZamani: null,
            interval: null
        }
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    mounted() {
        this.guncellemeZamaniYukle()
        this.interval = setInterval(() => {
            this.guncellemeZamaniYukle()
        }, 30000)
    },
    methods: {
      guncellemeZamaniYukle() {
          
          const { MusteriNo } = api.getUser()

          if (!MusteriNo) {
            this.interval = null
            return
          }

          api.guncellemeZamaniGetir({
              guncellemeZamani: `/${MusteriNo}`
          })
          .then(resp => resp.json())
          .then(data => {
              const dataGetir = data[0]

              if (dataGetir.length > 0) {
                  let tarih = moment(dataGetir[0].Tarih).format('DD.MM.YYYY')
                  let saat = dataGetir[0].Saat
                  
                  this.guncellemeZamani = `${tarih} - ${saat}`
              }
          })
      },
      closeSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      showSidebar() {
        this.$sidebar.displaySidebar(true)
      }
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    }
  };
</script>
