import { BASE_URL, HEADERS } from '../base'

const END_POINTS = {
    dashboard: 'dashboard',
    guncellemeZamaniGetir: 'guncelleme-zamani',
    subelerGetir: 'subeler',
    subeKutularGetir: 'kutular',
    masaDolulukOranlariGetir: 'masa-doluluk-orani',
    kasaRaporlariGetir: 'kasa',
    odemeSekilleriGetir: 'odeme-sekilleri',
    satisAdetleriGetir: 'satis-adetleri',
    iptalEdilenlerGetir: 'iptal-edilenler-raporu',
    stokAdetleriGetir: 'stok-adetleri',
    girisYap: 'giris',
}

const dashboard = (data) => {
    return fetch(BASE_URL + END_POINTS.dashboard, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const guncellemeZamaniGetir = (data) => {
    return fetch(BASE_URL + END_POINTS.guncellemeZamaniGetir + data.guncellemeZamani, {
        headers: HEADERS
    })
}

const subelerGetir = (data) => {
    return fetch(BASE_URL + END_POINTS.subelerGetir, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const subeKutularGetir = (data) => {
    return fetch(BASE_URL + END_POINTS.subeKutularGetir, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const masaDolulukOranlariGetir = (data) => {
    return fetch(BASE_URL + END_POINTS.masaDolulukOranlariGetir, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const kasaRaporlariGetir = (data) => {
    return fetch(BASE_URL + END_POINTS.kasaRaporlariGetir, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const odemeSekilleriGetir = (data) => {
    return fetch(BASE_URL + END_POINTS.odemeSekilleriGetir, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const satisAdetleriGetir = (data) => {
    return fetch(BASE_URL + END_POINTS.satisAdetleriGetir, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const iptalEdilenlerGetir = (data) => {
    return fetch(BASE_URL + END_POINTS.iptalEdilenlerGetir, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const stokAdetleriGetir = (data) => {
    return fetch(BASE_URL + END_POINTS.stokAdetleriGetir, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const girisYap = (data) => {
    return fetch(BASE_URL + END_POINTS.girisYap, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

export {
    dashboard,
    guncellemeZamaniGetir,
    subelerGetir,
    subeKutularGetir,
    masaDolulukOranlariGetir,
    kasaRaporlariGetir,
    odemeSekilleriGetir,
    satisAdetleriGetir,
    iptalEdilenlerGetir,
    stokAdetleriGetir,
    girisYap
}