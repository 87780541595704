import { BASE_URL, HEADERS } from '../base'

const END_POINTS = {
    giris: 'giris',
    kontrol: 'kontrol'
}

const girisYap = (data) => {
    return fetch(BASE_URL + END_POINTS.giris, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const kontrol = (data) => {
    return fetch(BASE_URL + END_POINTS.kontrol, {
        method: 'POST',
        headers: HEADERS,
        body: data,

    })
}

const sistemKontrolEt = (data) => {
    return fetch('http://crm-backend.atilimyazilim.com/api/integration/check', {
        method: 'POST',
        body: data,
        //headers: HEADERS_FN()
    })
}

// const testGet = (data) => {
//     return fetch(BASE_URL + END_POINTS.danismanlar + data.danismanlar, {
//         headers: HEADERS
//     })
// }

export {
    girisYap,
    kontrol,
    sistemKontrolEt
}