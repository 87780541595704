import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'

import api from './api/api'

Vue.use(Router)

let router = new Router({
  linkExactActiveClass: 'open active',
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'Anasayfa',
          component: () => import('./views/Dashboard.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/kasa-raporu',
          name: 'Kasa Raporu',
          component: () => import('./views/KasaRaporu.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/odeme-sekilleri-raporu',
          name: 'Ödeme Şekilleri Raporu',
          component: () => import('./views/OdemeSekilleriRaporu.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/satis-adetleri-raporu',
          name: 'Satış Adetleri Raporu',
          component: () => import('./views/SatisAdetleriRaporu.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/iptal-edilenler-raporu',
          name: 'İptal Edilenler Raporu',
          component: () => import('./views/IptalEdilenlerRaporu.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/masa-doluluk-orani',
          name: 'Masa Doluluk Oranları',
          component: () => import('./views/MasaDolulukOranlari.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/stok-raporu',
          name: 'Stok Raporu',
          component: () => import('./views/StokAdetleri.vue'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '/',
      redirect: 'giris',
      component: AuthLayout,
      children: [
        {
          path: '/giris',
          name: 'Giriş Yap',
          component: () => import('./views/Login.vue'),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: '/cikis',
          name: 'Çıkış Yap',
          component: () => import('./views/Cikis.vue'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
})

async function aktiflikKontrolEt() {
    const { ID, MusteriNo } = api.getUser()

    if (MusteriNo) {
        const form = new FormData()

        form.append('ID', ID)
        form.append('MusteriNo', MusteriNo)

        const response = await (await api.kontrol(form)).json();

        const { aktif } = response;

        if (!aktif) {
            api.User = {}
            localStorage.removeItem('yonetimPosPanel')
        }
    }
}

router.beforeEach(async (to, from, next) => {

  await aktiflikKontrolEt();

  let currentAdmin = localStorage.yonetimPosPanel ? JSON.parse(localStorage.getItem('yonetimPosPanel')) : undefined;

  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentAdmin) {
      next('/giris')
  } else if (!requiresAuth && currentAdmin) {
      next('/dashboard')
  } else {
      api.User = api.getUser()
      next()
  }

})



export default router