import * as giris from './endpoints/giris'
import *as dashboard from './endpoints/dashboard'

const localData = localStorage.yonetimPosPanel

let UserInfo = {

}

const getUser = () => {
    
    const localUserData = localStorage.yonetimPosPanel

    if (!localUserData) {
        return {
            
        }
    }

    return {
        ...JSON.parse(localUserData)
    }
}

if (localData) {
    UserInfo = {...JSON.parse(localData)}
}

const api = {
    getUser,
    User: UserInfo,
    ...giris,
    ...dashboard

}

export default api;